<template>
  <v-card>
    <v-card-title class="msaGrey">General Information</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" class="text-body-1 pt-8">
              GENERAL
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right" cols="3" class="mt-2"> Group </v-col>
            <v-col>
              <CustomizedAutoComplete
                :items="groups"
                :rules="[(v) => !!v || 'This field is required']"
                :value="edited.publicFormTemplateGroupId"
                @change="edited.publicFormTemplateGroupId = $event"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right" cols="3" class="mt-2"> Template Name </v-col>
            <v-col>
              <v-text-field
                v-model="edited.name"
                outlined
                dense
                :rules="required"
                placeholder="Enter form template name"
                maxlength="500"
                counter
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right" cols="3"> Description </v-col>
            <v-col>
              <v-textarea
                v-model="edited.description"
                outlined
                dense
                placeholder="Enter a description for the template (Optional)"
                maxlength="1000"
                counter
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="text-body-1 pt-8">
              OPTIONS
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-row align="center">
                <v-col align="right"> Supervisor Signature Required </v-col>
                <v-col class="shrink">
                  <v-switch
                    class="ma-0"
                    v-model="edited.supervisorApprovalRequired"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Manager Signature Required </v-col>
                <v-col class="shrink">
                  <v-switch
                    class="ma-0"
                    v-model="edited.managerApprovalRequired"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Safety Rep Signature Required </v-col>
                <v-col class="shrink">
                  <v-switch
                    class="ma-0"
                    v-model="edited.safetyRepApprovalRequired"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row align="center">
                <v-col align="right"> Allow Additional Hazards </v-col>
                <v-col class="shrink">
                  <v-switch
                    class="ma-0"
                    v-model="edited.allowAdditionalHazards"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Set template as private? </v-col>
                <v-col class="shrink">
                  <v-switch
                    class="ma-0"
                    v-model="edited.isPrivate"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Publish </v-col>
                <v-col class="shrink">
                  <v-switch
                    class="ma-0"
                    v-model="edited.isPublished"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="msaBlue white--text"
        @click="saveDetails()"
        :disabled="disableSave"
      >
        save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PublicFormTemplateDetailGeneralInfo',
  props: {
    formTemplate: {
      type: Object,
    },
    groups: {
      type: Array,
    },
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.formTemplate) == JSON.stringify(this.edited) ||
        this.edited.name == '' ||
        !this.edited.publicFormTemplateGroupId
      );
    },
  },
  data() {
    return {
      edited: {},
      required: [(v) => !!v || 'This field is required'],
    };
  },
  methods: {
    loadDetails() {
      this.edited = JSON.parse(JSON.stringify(this.formTemplate));
    },
    saveDetails() {
      const params = {
        loaderText: 'loading...',
        formTemplate: this.edited,
      };
      const url =
        this.edited.id == 0
          ? 'create-public-form-template?format=json'
          : 'edit-public-form-template?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          let id = this.edited.id;
          if (this.edited.id == 0) {
            id = response.data;
          }
          this.$store.commit('updateSelectedFormTemplate', {
            isPublished: this.edited.isPublished,
            groupId: this.edited.publicFormTemplateGroupId,
            name: this.edited.name,
            id,
            groupName: this.edited.publicFormTemplateGroupName,
          });
          if (this.edited.id == 0) {
            this.$router.replace({
              name: 'PublicFormTemplateDetail',
              params: {
                id,
              },
            });
            return;
          }
          this.$emit('saved');
        })
        .catch((error) => error);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
