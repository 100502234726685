<template>
  <v-card>
    <v-card-title class="msaGrey"> Sections </v-card-title>
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-simple-table
            :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
          >
            <template v-slot:default>
              <colgroup>
                <col span="1" />
                <col span="1" :style="{ width: '155px' }" />
              </colgroup>
              <tbody>
                <tr
                  v-for="(section, index) in sections"
                  :key="section.sectionId"
                >
                  <td class="msaBlue--text">
                    <span
                      @click="$emit('goToSection', section.sectionId)"
                      :style="{ cursor: 'pointer' }"
                    >
                      {{ section.sectionName }}
                    </span>
                  </td>
                  <td class="pl-0 pr-1" align="right" v-if="!isOrdering">
                    <FormTemplateSectionDetail
                      :section="section"
                      @saved="$emit('updateSection', $event)"
                    />
                    <v-tooltip bottom v-if="!isSearching">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-blur
                          text
                          small
                          v-on="on"
                          @click="
                            $emit('isOrdering');
                            section.isOrdering = 1;
                          "
                        >
                          <v-icon> mdi-swap-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>Change Position</span>
                    </v-tooltip>
                    <FormTemplateSectionDeletion
                      :sectionId="section.sectionId"
                      @deleted="$emit('deleteSection', section.sectionId)"
                    />
                  </td>
                  <td v-if="isOrdering && !section.isOrdering"></td>
                  <td v-if="section.isOrdering" class="pl-0 pr-1" align="right">
                    <ChangeOrderButtonGroup
                      :currentIndex="index"
                      :maxIndex="sections.length - 1"
                      @updatePositions="
                        $emit('updateSectionPositions');
                        section.isOrdering = 0;
                      "
                      @onOrder="$emit('onOrder', $event)"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-col align="right" v-if="!isOrdering" :key="reloadSectionDetail">
            <FormTemplateSectionDetail
              @saved="
                $emit('updateSection', $event);
                reloadSectionDetail = !reloadSectionDetail;
              "
            />
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import FormTemplateSectionDetail from '@/components/FormTemplates/FormTemplateSectionDetail.vue';
import FormTemplateSectionDeletion from '@/components/FormTemplates/FormTemplateSectionDeletion.vue';
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';

export default {
  name: 'FormTemplateSections',
  props: {
    sections: {
      type: Array,
    },
    isOrdering: {
      type: Number,
    },
    isSearching: {
      type: Boolean,
    },
  },
  components: {
    FormTemplateSectionDetail,
    FormTemplateSectionDeletion,
    ChangeOrderButtonGroup,
  },
  data() {
    return {
      reloadSectionDetail: false,
    };
  },
  methods: {},
};
</script>

<style></style>
