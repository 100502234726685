<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom v-if="section.sectionId == 0">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span>Add New Section</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
          >
            <v-icon color="msaBlue"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        {{ modalTitle }}
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <v-form ref="form" @submit.prevent :lazy-validation="false">
              <v-text-field
                v-model="edited.sectionName"
                outlined
                dense
                label="Section Name"
                maxlength="500"
                counter
                :rules="required"
              >
              </v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="close()"> cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          :disabled="disableSave"
          @click="save()"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'FormTemplateSectionDetail',
  props: {
    section: {
      type: Object,
      default() {
        return {
          sectionId: 0,
          sectionName: '',
          sectionPosition: 0,
          elements: [],
        };
      },
    },
  },
  data() {
    return {
      dialog: false,
      edited: {},
      required: [(v) => !!v || 'This field is required'],
    };
  },
  watch: {
    dialog(nv) {
      if (nv) {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      }
    },
  },
  computed: {
    modalTitle() {
      return this.edited.sectionId == 0 ? 'Add New Section' : 'Edit Section';
    },
    disableSave() {
      if (this.edited.sectionName == this.section.sectionName) {
        return true;
      }
      if (this.edited.sectionName && this.edited.sectionName.trim() == '') {
        return true;
      }
      if (!this.edited.sectionName) {
        return true;
      }
      return false;
    },
    isInternalAdmin() {
      return (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
    computedDefaultSection() {
      return this.isInternalAdmin
        ? {
            ...this.section,
            ...{
              publicFormTemplateId: this.$store.getters.selectedFormTemplate.id,
              publicFormTemplateGroupId:
                this.$store.getters.selectedFormTemplate.groupId,
            },
          }
        : {
            ...this.section,
            ...{
              formTemplateId: this.$store.getters.selectedFormTemplate.id,
              formTemplateGroupId:
                this.$store.getters.selectedFormTemplate.groupId,
            },
          };
    },
  },
  methods: {
    popData() {
      this.edited = { ...this.computedDefaultSection };
    },
    close() {
      this.dialog = false;
    },
    save() {
      const params = {
        loaderText: 'loading...',
        section: this.edited,
      };
      const url =
        this.edited.sectionId == 0
          ? this.isInternalAdmin
            ? 'create-public-form-template-section?format=json'
            : 'create-form-template-section?format=json'
          : this.isInternalAdmin
          ? 'edit-public-form-template-section?format=json'
          : 'edit-form-template-section?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          if (this.edited.sectionId == 0) {
            this.edited.sectionId = response.data;
          }
          this.$emit('saved', this.edited);
          this.close();
        })
        .catch((error) => error);
    },
  },
  mounted() {},
};
</script>
