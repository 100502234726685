import { render, staticRenderFns } from "./PublicFormTemplateDetailGeneralInfo.vue?vue&type=template&id=30347a04&scoped=true"
import script from "./PublicFormTemplateDetailGeneralInfo.vue?vue&type=script&lang=js"
export * from "./PublicFormTemplateDetailGeneralInfo.vue?vue&type=script&lang=js"
import style0 from "./PublicFormTemplateDetailGeneralInfo.vue?vue&type=style&index=0&id=30347a04&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30347a04",
  null
  
)

export default component.exports