<template>
  <v-card>
    <v-card-title class="msaGrey">General Information</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" class="text-body-1 pt-8">
              GENERAL
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right" cols="3" class="mt-2"> Group </v-col>
            <v-col>
              <CustomizedAutoComplete
                :items="groups"
                :rules="[(v) => !!v || 'This field is required']"
                :value="edited.formTemplateGroupId"
                @change="edited.formTemplateGroupId = $event"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right" cols="3" class="mt-2"> Template Name </v-col>
            <v-col>
              <v-text-field
                v-model.trim="edited.name"
                :rules="required"
                counter
                dense
                flat
                label="Enter form template name"
                maxlength="500"
                outlined
                placeholder="Enter form template name"
                solo
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right" cols="3"> Description </v-col>
            <v-col>
              <v-textarea
                v-model.trim="edited.description"
                outlined
                dense
                placeholder="Enter a description for the template (Optional)"
                maxlength="1000"
                counter
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col align="right" cols="3"> Watchers </v-col>
            <v-col cols="9">
              <CustomizedAutoComplete
                :items="employees"
                :value="edited.watchers"
                @change="edited.watchers = $event"
                clearable
                deletable-chips
                hide-details
                item-text="fullname"
                label="Select user(s)"
                multiple
              />
            </v-col>
            <!-- Offset for missing row header -->
            <v-col cols="3" />
            <v-col class="pt-0">
              Select users that should be notified when anyone fill a form from
              this template.
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="text-body-1 pt-8">
              OPTIONS
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-row align="center">
                <v-col align="right"> Supervisor Signature Required </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="edited.supervisorApprovalRequired"
                    class="ma-0"
                    hide-details
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Manager Signature Required </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="edited.managerApprovalRequired"
                    class="ma-0"
                    hide-details
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Safety Rep Signature Required </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="edited.safetyRepApprovalRequired"
                    class="ma-0"
                    hide-details
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row align="center">
                <v-col align="right"> Allow Additional Hazards </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="edited.allowAdditionalHazards"
                    class="ma-0"
                    hide-details
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Allow Duplication </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="edited.isDuplicable"
                    class="ma-0"
                    hide-details
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Allow Tags </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="edited.allowTags"
                    class="ma-0"
                    hide-details
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Set template as private? </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="edited.isPrivate"
                    class="ma-0"
                    hide-details
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col align="right"> Publish </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="edited.isPublished"
                    class="ma-0"
                    hide-details
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :disabled="disableSave"
        @click="saveDetails()"
        class="msaBlue white--text"
      >
        SAVE
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'FormTemplateDetailGeneralInfo',
  props: {
    formTemplate: {
      type: Object,
    },
    groups: {
      type: Array,
    },
    employees: {
      type: Array,
    },
  },
  data() {
    return {
      edited: {},
      required: [(v) => !!v?.trim() || 'This field is required'],
    };
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.formTemplate) == JSON.stringify(this.edited) ||
        this.edited.name?.trim() == '' ||
        !this.edited.formTemplateGroupId
      );
    },
  },
  methods: {
    loadDetails() {
      this.edited = JSON.parse(JSON.stringify(this.formTemplate));
    },
    saveDetails() {
      const params = {
        loaderText: 'Loading...',
        formTemplate: this.edited,
      };
      const url =
        this.edited.id == 0
          ? 'create-form-template?format=json'
          : 'edit-form-template?format=json';

      this.$axios.post(url, params).then((response) => {
        let id = this.edited.id;
        if (this.edited.id == 0) {
          id = response.data;
        }
        this.$store.commit('updateSelectedFormTemplate', {
          isPublished: this.edited.isPublished,
          groupId: this.edited.formTemplateGroupId,
          name: this.edited.name,
          id,
          groupName: this.edited.formTemplateGroupName,
        });
        if (this.edited.id == 0) {
          this.$router.replace({
            name: 'FormTemplateDetail',
            params: {
              id,
            },
          });
          return;
        }
        this.$emit('saved');
      });
    },
  },
};
</script>
