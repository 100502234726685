var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"msaGrey"},[_vm._v(_vm._s(_vm.section.sectionName))]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{style:({ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }),attrs:{"headers":_vm.headers,"items":_vm.section.elements,"disable-pagination":"","hide-default-footer":"","disable-filtering":"","mobile-breakpoint":"960","disable-sort":_vm.isMobile},scopedSlots:_vm._u([{key:`item`,fn:function({ item, index }){return [(!_vm.isMobile)?_c('tr',[_c('td',{style:(_vm.inputRequiredStyle(item)),attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.typeIcon(item.typeId))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.typeName(item.typeId))+" ")])])],1),_c('td',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.elementName)+" ")]),_c('v-col',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.description)+" ")])],1)],1),_c('td',{attrs:{"align":"center"}},[(!_vm.isOrdering)?_c('span',[_c('FormTemplateElementDetail',{attrs:{"section":_vm.section,"element":item,"sections":_vm.sections,"elementTypes":_vm.elementTypes},on:{"saved":function($event){return _vm.$emit('elementSaved', $event)},"sectionChanged":function($event){return _vm.removeElement(index, _vm.section)}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"msaBlue"},on:{"click":function($event){return _vm.duplicateElement(
                        item.elementId,
                        item.elementName.length,
                      )}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(" Duplicate ")])]),(!_vm.isSearching)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"text":"","small":""},on:{"click":function($event){_vm.$emit('isOrdering');
                      item.isOrdering = 1;}}},on),[_c('v-icon',[_vm._v(" mdi-swap-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("Change Position")])]):_vm._e(),_c('FormTemplateElementDeletion',{attrs:{"elementId":item.elementId},on:{"deleted":function($event){return _vm.removeElement(index, _vm.section)}}})],1):_vm._e(),(_vm.isOrdering && !item.isOrdering)?_c('span'):_vm._e(),(item.isOrdering)?_c('span',{staticClass:"pl-0 pr-1",attrs:{"align":"center"}},[_c('ChangeOrderButtonGroup',{attrs:{"currentIndex":index,"maxIndex":_vm.section.elements.length - 1},on:{"updatePositions":function($event){_vm.$emit('updateElementPositions');
                  item.isOrdering = 0;},"onOrder":function($event){return _vm.$emit('onOrder', $event)}}})],1):_vm._e()])]):_vm._e(),(_vm.isMobile)?_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Question ")]),_c('v-col',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(item.elementName)+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Type ")]),_c('v-col',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.typeName(item.typeId))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Input Required ")]),_c('v-col',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(item.inputRequired ? 'Yes' : 'No')+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Actions ")]),_c('v-col',{attrs:{"align":"right"}},[(!_vm.isOrdering)?_c('span',[_c('FormTemplateElementDetail',{attrs:{"section":_vm.section,"element":item,"sections":_vm.sections,"elementTypes":_vm.elementTypes},on:{"saved":function($event){return _vm.$emit('elementSaved', $event)},"sectionChanged":function($event){return _vm.removeElement(index, _vm.section)}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"msaBlue"},on:{"click":function($event){return _vm.duplicateElement(
                            item.elementId,
                            item.elementName.length,
                          )}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(" Duplicate ")])]),(!_vm.isSearching)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"text":"","small":""},on:{"click":function($event){_vm.$emit('isOrdering');
                          item.isOrdering = 1;}}},on),[_c('v-icon',[_vm._v(" mdi-swap-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("Change Position")])]):_vm._e(),_c('FormTemplateElementDeletion',{attrs:{"elementId":item.elementId},on:{"deleted":function($event){return _vm.removeElement(index, _vm.section)}}})],1):_vm._e(),(_vm.isOrdering && !item.isOrdering)?_c('span'):_vm._e(),(item.isOrdering)?_c('span',{staticClass:"pl-0 pr-1",attrs:{"align":"center"}},[_c('ChangeOrderButtonGroup',{attrs:{"currentIndex":index,"maxIndex":_vm.section.elements.length - 1},on:{"updatePositions":function($event){_vm.$emit('updateElementPositions');
                      item.isOrdering = 0;},"onOrder":function($event){return _vm.$emit('onOrder', $event)}}})],1):_vm._e()])],1)],1)],1):_vm._e()]}}],null,true)})],1),(!_vm.isOrdering)?_c('v-card-actions',{key:_vm.reloadElementDetail},[_c('v-spacer'),_c('FormTemplateElementDetail',{attrs:{"section":_vm.section,"sections":_vm.sections,"elementTypes":_vm.elementTypes},on:{"saved":function($event){return _vm.$emit('elementSaved', $event)},"close":function($event){_vm.reloadElementDetail = !_vm.reloadElementDetail}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }